<template>
    <div>
        <div v-if="authUserPermission['form-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('form') }}</h3>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search')" v-on:keyup="filter"/>                        
                                </div>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['form-create']">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('form_code')" :prop="tt('form_code')" sortable>
                                <template v-slot="{row}">
                                    {{row.form_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('form_description')" :prop="tt('form_description')"  min-width="140px" sortable>
                                <template v-slot="{row}">
                                    {{row.form_description}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('form_type')" :prop="tt('form_type')" sortable>
                                <template v-slot="{row}">
                                    {{row.form_type}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('role')" :prop="tt('role')" sortable>
                                <template v-slot="{row}">
                                    {{row.role}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('company_code')" :prop="tt('company_code')" min-width="95px" sortable>
                                <template v-slot="{row}">
                                    <span v-for="v in row.rel_form_company">
                                        <label class="badge badge-success mr-1">{{v.company_code}}</label>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['form-update'] || authUserPermission['form-delete']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['form-update']">Edit</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['form-delete']">Delete</el-dropdown-item>                                        
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <skeleton-loading v-else/>
                    </div>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>

            <!-- MODAL CREATE -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <label class="form-control-label">{{ tt('form_code') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('form_code')" :placeholder="tt('form_code')" v-model="formMaster.form_code" rules="required"></base-input>
                        <label class="form-control-label">{{ tt('form_description') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('form_description')" :placeholder="tt('form_description')" v-model="formMaster.form_description" rules="required"></base-input>
                        <label class="form-control-label">{{ tt('form_type') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('form_type')" :placeholder="tt('form_type')" v-model="formMaster.form_type" rules="required"></base-input>
                        <label class="form-control-label">{{ tt('role') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('role')" rules="required">
                            <el-select class="select-danger" v-model="formMaster.role" :placeholder="tt('choose_role')">
                                <el-option :value="r.name" :label="r.name" :key="r.id" v-for="r in roleMaster"></el-option>
                            </el-select>
                        </base-input>
                        <label class="form-control-label">{{ tt('company') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('menu')" rules="required">
                            <el-select class="select-danger" v-model="formMaster.company_code" multiple :placeholder="tt('choose_company')">
                                <el-option class="select-danger" :value="cc['company_code']" :label="cc['company_code'] +' - '+ cc['company_description']" :key="cc['company_code']" v-for="cc in  companyCode"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import formMaster from '@/services/setting/form.service';
    import roleMaster from '@/services/manage/manageRole.service';
    import companyCode from '@/services/master/companyCode.service';

    export default {        
        data() {
            return {                   
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },           
                form: {
                    add: true,
                    title: this.tt('add_formMaster'),
                    show: false
                }, 
                table: {
                    search: (this.$route.query.search) ? this.$route.query.search : '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },     
                errorMessage: null,
                links: [],
                formMaster: {},
                roleMaster: {},
                companyCode: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get();
            this.getRole()
            this.getcompanyCode()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, formMaster.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data  = response.data.data.data.data;  
                    context.table.page  = response.data.data.data; 
                    context.onLoad      = false;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            getRole() { 
                let context = this;               
                Api(context, roleMaster.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.roleMaster = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.roleMaster = [];
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            getcompanyCode() {
                let context = this;               
                Api(context, companyCode.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.companyCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.companyCode = [];
                    }
                })
                .call()
            },
            filter() {
                if(this.table.search != this.$route.query.search){
                    this.onLoad = true;
                    this.table.page.current_page = 1;

                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.table.search == ''){
                            this.$router.push({path:'/setting/form', query:null})
                        }else{
                            this.$router.push({path:'/setting/form', query:{search:this.table.search}})
                        }
                        this.get()
                    }, 100);
                }
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add');
                this.form.show = true;
                this.formMaster = {};
            },            
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, formMaster.show(id)).onSuccess(function(response) {
                    context.formMaster = response.data.data;
                    var rel_form_company = [];
                    response.data.data.rel_form_company.forEach(function(item) {
                        rel_form_company.push(item.company_code)
                    })
                    context.formMaster.company_code = rel_form_company
                    context.form.add = false;
                    context.form.title = context.tt('edit');
                    context.form.show = true;                    
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;

                if (this.form.add) {
                    api = Api(context, formMaster.create(this.formMaster));
                } else {{
                    api = Api(context, formMaster.update(this.formMaster.id, this.formMaster));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: "bottom",
			            horizontalAlign: "left"
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, formMaster.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
<style></style>
