import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('form?page=' + page, {params});
    }, 
    getByRole() {
        return Service().get('form/getByRole');
    },
    show(id) {
        return Service().get('form/show/'+id);
    },    
    create(params) {
        return Service().post('form/create', params);
    },
    update(id, params) {
        return Service().post('form/update/'+id, params);
    },
    delete(id) {
        return Service().post('form/delete/'+id);
    }
}